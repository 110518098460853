
export class EvalAccountParameters {
    customer_name!: string;
    licensed_apps!: string;
    default_contact_email!: string;
    default_admin_username!: string;
    country!: string;
    constructor(data: object) {
      Object.assign(this, data);
    }
  }