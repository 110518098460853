export class GlobalAppConfig {
  uid!: string;
  name!: string;
  description?: string;
  value!: string;
  createdAt!: string;
  updatedAt!: string;
  constructor(data: object) {
    Object.assign(this, data);
  }
}
